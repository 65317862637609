
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  h1 { @apply text-5xl font-extrabold text-christi-700 m-5 }
  h2 { @apply text-4xl font-bold text-gold-drop-600 m-5 }
  h3 { @apply text-xl font-semibold text-rio-grande-500 italic m-5 }
  h4 { @apply text-lg font-medium italic text-my-sin-700 m-5 }
}

.p-carousel-prev, .p-carousel-next {
  display:none;
  visibility: hidden;
}

.p-multiselect {
  @apply w-full;
}


